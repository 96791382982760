.lastSection {
    background-color: rgb(35, 35, 35);
    margin: 0;
    width: 100%;
    height: 450px;
    font-family: Roboto, sans-serif;
    color: #fff;
}

.footer {
    margin-top: 1300px;
    width: 100%;
    height: 400px;
    background-color: rgb(35, 35, 35);
    color: white;
    display: flex;
    justify-content: space-evenly;
    font-family: Roboto, sans-serif;
    /* position: absolute; */
}

.footer div {
    width: 25%;
    margin-top: 50px;
}

.footer div h2 {
    text-align: left;
    font-size: 1.4em;
}

.footer div p {
    width: 70%;
    text-align: left;
}

.logoPart img {
    margin-top: 0;
    width: 300px;
    height: 200px;
}

.footer hr {
    color: #fff;
    height: 1px;
}

.rights {
    text-align: center;
    height: 10px;
    background-color: rgb(35, 35, 35);
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 2px solid white;
}

@media (max-width: 900px) {
    .lastSection {
        background-color: rgb(35, 35, 35);
        margin-top: 2450px;
        width: 100%;
        height: 1300px;
        font-family: Roboto, sans-serif;
        color: #fff;
    }

    .footer {
        margin-top: 20px;
        padding-top: 500px;
        width: 100%;
        height: 200px;
        background-color: rgb(35, 35, 35);
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        font-family: Roboto, sans-serif;
    }

    .footer div {
        width: 60%;
    }

    .logoPart{
        margin-left: 12%;
        width: 300px;
        height: 200px;
    }

    .leftPart{
        margin-left: 30%;
    }

    .leftPart h2{
        margin-left: 12%;
    }
 
    .centralPart {
        margin-left: 30%;
    }

    .centralPart h2{
        margin-left: 12%;
    }

    .rightPart {
        margin-left: 15%;
    }

    .rightPart h2 {
        margin-left: 26%;
    }

    .rightPart p {
        margin-left: 25%;
    }
    
    .rights {
        margin-top: 550px;
        text-align: center;
    }
}