

.section {
  width: 100%;
  color: white;
  margin-top: 40px;
  font-family:Roboto, sans-serif;
}

@media screen and (min-width: 992px) {
  .section {
    width: 100%;
  }
}

.titleReview{
    text-align: center;
}

.icon {
  font-size: 3rem;
  color: hsl(21, 62%, 45%);
}

.sectionCenter {
  /* margin: 0 auto;
  margin-top: 4rem; */
  width: 100%;
  height: 450px;
  /* max-width: 800px; */
  text-align: center;
  position: relative;
  display: flex;
  overflow: hidden;
}

article {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.3s linear;
}

article.activeSlide {
  opacity: 1;
  transform: translateX(0);
}
article.lastSlide {
  transform: translateX(-100%);
}
article.nextSlide {
  transform: translateX(100%);
}

.reviewContainer {
    width: 100%;
    height: 300px;
    border-radius: 0 0 100% 0;
}

.reviewContainer h1 {
    padding-top: 50px;
    font-size: 2.5em;
    color: white;
    margin: 0;
}

article h4 {
  text-transform: uppercase;
  color: hsl(21, 62%, 45%);
  margin-bottom: 0.25rem;
}
.titleReview {
  text-transform: capitalize;
  margin-bottom: 0.75rem;
  color: hsl(209, 34%, 30%);
}

.titleReview {
  text-align: center;
  margin-bottom: 2rem;
}

.title h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}



.text {
  max-width: 35em;
  margin: 0 auto;
  margin-top: 2rem;
  line-height: 2;
  color: hsl(210, 22%, 49%);
}

.prev,
.next {
  position: absolute;
  top: 200px;
  transform: translateY(-50%);
  background: hsl(210, 22%, 49%);
  color: #fff;
  width: 1.25rem;
  height: 1.25rem;
  display: grid;
  place-items: center;
  border-color: transparent;
  font-size: 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.3s linear;
}



.prev:hover,
.next:hover {
  background: hsl(21, 62%, 45%);
}
.prev {
  left: 0;
}
.next {
  right: 0;
}
@media (min-width: 800px) {
  .text {
    max-width: 45em;
  }
  .prev,
  .next {
    width: 2rem;
    height: 2rem;
    font-size: 1.5rem;
  }
}


@media (max-width: 900px) {

  .ikona {
    padding-right: 100px;
  }

   .review {
    margin-left: 20px;
    margin-right: 40px;
    width: 70%;
   }

   .name {
    margin-left: 20px;
    margin-right: 140px;
   }
}
