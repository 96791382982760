 .header {
   background-color: rgb(35, 34, 34);
    font-family: Roboto, sans-serif;
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: auto;
    transition: 0.6s;
    z-index: 100000;
 }


 .navbar ul{
    display: flex;
    justify-content: flex-end;
    transition: 1s ease;
 }

 .navbar .logo {
    position: relative;
    transition: 0.6s;
}


.logo {
    width: 150px;
    height: 120px;
    margin-top: -20px;
    margin-bottom: -80px;
    margin-right: 550px;
} 

 .navbar ul li {
    margin-block: .5rem;
    margin-inline: 2rem;
    list-style: none;
 }

 .navbar ul li a {
    font-size: 20px;
    text-decoration: none;
    color: #fff;
    /* transition: 0.3s ease; */
 }

 .navbar ul li a:hover {
    border-bottom: 3px solid red;
    border-radius: 20px;
    height: 3px;
    bottom: 0;
    left: 0;
    transition: 0.3s ease-in-out;
 }

 
 .menuIcon i{
    display: none;
    color: #fff;
    font-size: 30px;
    cursor: pointer;
 }



 .closed {
    display: none;
    width: 100%;
    cursor: pointer;
    color: white;
    justify-content: flex-end;
    font-size: 30px;
 }



 @media (max-width: 900px) {

   .header {
      background-color: rgb(35, 34, 34);
       font-family: Roboto, sans-serif;
       overflow: hidden;
       position: fixed;
       width: 100%;
       height: auto;
       transition: 0.6s;
       z-index: 9999999999999999999;
       /* border-radius: 0 0 100% 45%; */
    }

    .navbar {
        position: fixed;
        min-width: 100%;
        height: 100vh;
        top: -110%;
        background: rgb(35, 34, 34); 
        transition: 1s ease;
        z-index: 999999999999999;
     }
    
     .navbar.active {
        top: 0;
        transition: 1s ease;
     }
    
     .navbar .logo {
      position: relative;
      transition: 0.6s;
  }
  
  
   .logo {
      width: 150px;
      height: 120px;
      margin-top: -10px;
      margin-bottom: -80px;
      margin-right: 150px;
  } 

     .navbar ul {
        display: flex;
        flex-direction: column;
        align-items: center;
     }
     
     .navbar ul li {
        margin-block: 2.5rem;
        list-style: none;
     }
    
     .navbar ul li a {
        font-size: 25px;
        text-decoration: none;
        color: #fff;
     }
    
     .menuIcon i{
        display: flex;
        width: 100%;
        color: #fff;
        font-size: 30px;
        cursor: pointer;
        /* justify-content: flex-end; */
        padding-left: 380px;
     }
   

     .closed {
        padding-right: 30px;
        display: flex;
        width: 100%;
        cursor: pointer;
        color: white;
        font-size: 30px;
        justify-content: flex-end;
     }
 }
