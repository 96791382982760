
.homeImg {
    width: 100%;
  }

  .image {
    display: none;
    width: 100%;
  }

  .imgWrapper {
    width: 100%;
    height: 600px;
  }

  @keyframes blender {
    0% {
      opacity: 1;
      transition: 5s ease-in-out;
    }
    10% {
        opacity: 1;
        transition: transform  10s ease-in-out;
      }
    20% {
      opacity: 1;
      transition: transform  15s ease-in-out;
    }
    30% {
      opacity: 1;
      transition: transform  20s ease-in-out;
    }
   40% {
      opacity: 1;
      transition: transform  25s ease-in-out;
    }
    50% {
        opacity: 1;
        transition: transform  30s ease-in-out;
      }
      60% {
        opacity: 1;
        transition: transform  35s ease-in-out;
      }
      70% {
        opacity: 1;
        transition: transform 40s ease-in-out;
      }
      80% {
        opacity: 1;
        transition: transform 45s ease-in-out;
      }
      90% {
        opacity: 1;
        transition: transform 50s ease-in-out
      }
      100% {
        opacity: 1;
        transition: transform 55s ease-in-out;
      }
  }

  .imageActive {
    width: 100%;
    height: 700px;
    background-position: 'center';
    background-size: 'cover';
    background-repeat: no-repeat;
    filter: brightness(60%);
    transition:  5s;
    animation-name: blender;
    animation-direction: alternate-reverse;
    animation-duration: 60s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-play-state: running;
    animation-timing-function: ease-in-out;
  }
  
  @media (max-width: 900px){
    .imgWrapper {
      width: 100%;
      height: 400px;
    }

    .imageActive {
      width: 100%;
      height: 400px;
      background-position: 'center';
      background-size: 'cover';
      background-repeat: no-repeat;
    }
  }