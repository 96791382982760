.catalog {
    width: 100%;
    height: 500px;
    margin-top: 140px;
    background-size: cover;
    background-position: center;
    background-image: url('../Main-components/Components Assets/wood.avif');
    filter: brightness(90%);
}

.catalog h2 {
    font-family: Roboto, sans-serif;
    font-size: 2.5em;
    text-align: center;
    padding-top: 180px;
    color: rgb(2, 2, 55);
}

.catalog a {
    text-decoration: none;
}

.catalog button {
    display: flex;
    background-color: transparent;
    color: rgb(2, 2, 55);
    border: 1px solid rgb(2, 2, 55);
    border-radius: 5px;
    margin-left: 46%;
    width: 120px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 25px;
    cursor: pointer;
    font-size: 1.1em;
}

.catalog button:hover {
    background-color: white;
    transition: 0.3s ease-in-out;
}

.catalog .fa-solid {
    margin-left: 5px;
}

@media (max-width: 900px){
    .catalog {
        margin-top: 20px;
    }
    .catalog button {
        margin-left: 36%;
    }
}