
body {
  margin: 0;
  width: 100%;
  height: 100%;
}

.App {
  margin: 0;
}

.App {
  font-family: sans-serif;
  /* text-align: center; */
}
