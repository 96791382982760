.heroContainer {
    width: 100%;
    height: 300px;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    background-color: rgb(226, 226, 226);
    font-family: Roboto, sans-serif;
    padding-top: 20px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}

.heroContainer div{
    width: 25%;
    margin: 20px;
}

.heroContainer div h3{
    font-size: 1.5em;
 }

.heroContainer div h3 i{
   font-size: 2.3em;
}

@media (max-width: 900px) {
    .heroContainer {
        width: 100%;
        height: 1650px;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
    }

    .heroContainer div{
        width: 30%;
        margin-left: 35%;
        margin-right: 35%;
        text-align: center;
    }
}